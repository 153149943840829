/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { Typography, Grid, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
import { BorderLinearProgress } from '../../Dashboard/components/BadgesAndTrophies/BadgesAndTrophies.styled';
import { truncateText } from '../../Artifact/components/helper';
import { getCurrentYear } from '../../../utils/utils';
import { StyledCustomizedWrapper2 } from '../../Dashboard/Dashboard.styled';

interface CategoryProps {
  categoryName: string;
  name: any;
  minimumScore: string;
  label: string;
  totalPoints: number;
  categoryPoints: number;
}

const ProgressReport = ({ categoryDetails, hideTitle, isProfileHeader }) => {
  const [progressData, setProgressData] = useState<any[]>([]);
  const [check, setCheck] = useState(false);
  const loggedInUserId = localStorage.getItem('user_id');
  // const [loading, setLoading] = useState<boolean>(true);
  const { userId } = useParams();
  const currentYear = (useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear())?.toString();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CommHandler.request({
          url: `${config.apiBaseUrl}/artifactPoints/score?userId=${
            userId || loggedInUserId
          }`,
          method: 'GET',
          params: {
            year: currentYear,
            status: 1,
          },
        });
        if (response.status === 404) {
          setCheck(true);
        }
        if (response?.data) {
          setProgressData(response?.data);
        }
      } catch (error) {
        console.error('Error fetching rank:', error);
      } finally {
        // setLoading(false);
      }
    };
    fetchData();
  }, [loggedInUserId, userId, currentYear]);

  const category = (records: CategoryProps) => {
    const truncatedLabel = truncateText(
      check ? records?.name : records?.categoryName,
      8,
    );

    const percentage = (records?.categoryPoints > Number(records?.minimumScore)
      ? Number(records?.minimumScore)
      : (records?.categoryPoints ?? 0) / Number(records.minimumScore)) * 100 || 0;
    const checkPercentage = percentage > 100 ? 100 : Math.floor(percentage);
    return (
      <Box
        className="badge-strip-wrap"
        key={check ? records.name : records.label}
        sx={{
          display: 'flex',
          marginBottom: '10px',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tooltip
          title={check ? records?.name : records?.categoryName}
          arrow
        >
          <Box className="avatar-wrap">
            <Typography
              className="badge-icon-title"
              variant="body2"
              sx={{ marginRight: '12px', maxWidth: '95px' }}
            >
              {truncatedLabel}
            </Typography>
          </Box>
        </Tooltip>
        <Grid
          item
          xs
          className="borderLinearProgressWrapper"
        >
          <Grid
            container
            justifyContent="center"
          >
            <BorderLinearProgress
              variant="determinate"
              value={check ? 0 : checkPercentage}
              className="badge-progress-bar"
            />
          </Grid>
        </Grid>
        <Grid
          item
          style={{ minWidth: '135px', textAlign: 'left' }}
        >
          <Typography
            className="actual-points-text"
            variant="caption"
            style={{ margin: '0 4px' }}
          >
            {`${
              check
                ? 0
                : records?.categoryPoints
                > Number(records?.minimumScore)
                  ? Number(records?.minimumScore)
                  : records?.categoryPoints
            } / ${check ? 0 : records?.minimumScore} PTS `}
          </Typography>
          <Typography
            className="percentage-text"
            variant="caption"
            color="#7B7B7B"
          >
            {`${check ? 0 : checkPercentage}%`}
          </Typography>
        </Grid>
      </Box>
    );
  };

  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <StyledCustomizedWrapper2 className="dashbaodProfile">
        <Grid
          container
          direction="column"
          spacing={2}
          className="scoring-dash"
        >
          {hideTitle ? null : (
            <Grid item xs={12}>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontFamily: 'Lato',
                  fontWeight: '700',
                  color: '#06152B',
                }}
              >
                Progress Report
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              className="progress-bar-report"
              style={{
                overflowY: 'auto', maxHeight: isProfileHeader ? '143px' : '460px',
              }}
            >
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '24px',
                  // minWidth: '450px',
                  position: 'relative',
                }}

              >
                {(check ? categoryDetails || [] : progressData || []).map((catname) => category(catname))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </StyledCustomizedWrapper2>
    </Grid>
  );
};

export default ProgressReport;

import React, { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PrivateAppLayout from '../layouts/PrivateAppLayout';
import AuthRoute from './AuthRoute';
// import { Artifact } from '../modules/Artifact';
import { BUArtifact } from '../modules/BUArtifact';
import CompanyArtifact from '../modules/BUArtifact/CompanyArtifacts';
import { Profile } from '../modules/Profile';
import PreviewPage from '../modules/Artifact/components/PreviewPage';
import { NotFound } from '../modules/NotFound';
import AddArtifacts from '../modules/Artifact/components/AddArtifacts';
import ViewArtifactDetails from '../modules/Artifact/components/ViewArtifact';
import { ErrorBoundary } from '../components/ErrorBoundary';
import ArtifactList from '../modules/Artifact/components/ArtifactList';
import { getCurrentYear } from '../utils/utils';
// import { AddProjectLevelArtifact } from '../modules/Artifact/components';
import AddProjectLevelArtifact from '../modules/Artifact/components/form';
import { storeAppraisalCycleSelected } from '../modules/Profile/components/AppraisalCycleSlice';
import ViewAllArtifact from '../modules/Artifact/components/ViewAllArtifact';
import Growth from '../modules/Growth/Growth';

const Dashboard = lazy(() => import('../modules/Dashboard/Dashboard'));
const LoginPage = lazy(() => import('../modules/Login/Login'));
const LiveRankingExpend = lazy(() => import('../modules/Dashboard/components/LiveRanking/LiveRankingExpend'));

const AppRouter = () => {
  const dispatch = useDispatch();
  dispatch(storeAppraisalCycleSelected(getCurrentYear()));
  const authAppRouter = createBrowserRouter([
    {
      path: '/',
      element: <AuthRoute />,
      children: [
        {
          path: '/',
          element: <PrivateAppLayout />,
          children: [
            {
              path: '/',
              element: <Dashboard />,
            },
            {
              path: '/team-artifacts',
              element: <BUArtifact />,
            },
            {
              path: '/company-artifacts',
              element: <CompanyArtifact />,
            },
            {
              path: '/artifacts/add-artifacts',
              element: <AddArtifacts />,
            },
            {
              path: '/artifacts/add-artifacts/form/:text/:id/:categoryCode',
              element: <AddProjectLevelArtifact />,
            },
            {
              path: '/artifact-detail',
              element: <ArtifactList />,
            },
            {
              path: '/user-artifact-detail/:userId',
              element: <ArtifactList />,
            },
            {
              path: '/artifacts/add-artifacts/preview',
              element: <PreviewPage />,
            },
            {
              path: '/liveRankingExpand',
              element: <LiveRankingExpend rankings={[]} />,
            },
            {
              path: '/Profile',
              element: <Profile />,
            },
            {
              path: '/artifacts/editArtifact/:id/:categoryId',
              element: <AddProjectLevelArtifact />,
            },
            {
              path: '/artifacts/viewArtifact/:artifactFormId',
              element: <ViewArtifactDetails />,
            },
            {
              path: '/artifacts/viewAll-artifact/:userId?',
              element: <ViewAllArtifact />,
            },
            {
              path: '/growth',
              element: <Growth />,
            },
          ],
        },
      ],
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/*',
      element: <NotFound />,
    },
  ]);

  return (<RouterProvider router={authAppRouter} />);
};

export default AppRouter;

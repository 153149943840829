import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Avatar, Box, Card, CardContent, Grid, List, ListItem, ListItemAvatar, ListItemText,
} from '@mui/material';
import { Chart, registerables } from 'chart.js';
import { RootState } from '../../store/type';
import { StyledCustomizedDivider, StyledCustomizedWrapper, StyledCustomizedWrapper1 } from '../Dashboard/Dashboard.styled';
import { UserProfiles } from '../Dashboard/components/UserProfile';
import { GrowthAppraisal, GrowthAppraisalContainer } from './Growth.styled';
Chart.register(...registerables);
const Growth = () => {
  const user = useSelector((state: RootState) => state.user.employee);
  const [showPercentage, setShowPercentage] = useState(false);
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);
  const [chartData, setChartData] = useState<number[]>([]);
  const [chartLabels, setChartLabels] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('your-api-endpoint');
        // const data = await response.json();
        const simulatedData = [40, 70, 80, 30, 4.5, 37, 25];
        const simulatedLabels = ['2017', '2019', '2021', '2023', '2024', '2018', '2020'];
        setChartData(simulatedData);
        setChartLabels(simulatedLabels);
        // setChartData(data.chartData); // Assuming API returns { chartData: [], chartLabels: [] }
        // setChartLabels(data.chartLabels);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const myChart = chartRef.current;
    const ctx = myChart.getContext('2d');

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
    if (ctx) {
      chartInstanceRef.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: [
            {
              data: chartData,
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              max: 100,
              title: {
                display: true,
                text: 'Hike%',
                font: {
                  size: 18,
                  weight: 'bold',
                },
              },
            },
            x: {
              title: {
                display: true,
                text: 'Year',
                font: {
                  size: 18,
                  weight: 'bold',
                },
              },
              grid: {
                drawOnChartArea: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Growth Chart',
              font: {
                size: 20,
              },
              position: 'top',
              align: 'start',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [chartData, chartLabels]);

  const handleToggleVisibility = () => {
    setShowPercentage(!showPercentage);
  };
  return (
    <>
      <StyledCustomizedWrapper>
        <UserProfiles {...user} />
        <StyledCustomizedDivider />
        <GrowthAppraisal>
          <GrowthAppraisalContainer className="rank-list">
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'center', sm: 'flex-start' },
              }}
            >
              <ListItem>
                <ListItemAvatar sx={{ minWidth: 0, marginRight: 1 }}>
                  <Avatar src="/Growth.svg" sx={{ width: 40, height: 40 }} />
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    '& .MuiListItemText-primary': {
                      whiteSpace: 'nowrap',
                      textOverflow: 'clip',
                      marginBottom: '4px',
                    },
                    '& .MuiListItemText-secondary': {
                      display: 'flex',
                      alignItems: 'center',
                      padding: 0,
                    },
                  }}
                  primary="Appraisal Percentage"
                  secondary={(
                    <Box style={{ display: 'flex', alignItems: 'start' }}>
                      {showPercentage ? <span>150%</span> : <span>XXX%</span>}
                      {showPercentage ? <VisibilityIcon onClick={handleToggleVisibility} style={{ color: 'Blue' }} /> : <VisibilityOffIcon onClick={handleToggleVisibility} style={{ color: 'Blue' }} />}
                    </Box>
                  )}
                />
              </ListItem>
            </List>
          </GrowthAppraisalContainer>
        </GrowthAppraisal>
      </StyledCustomizedWrapper>
      <StyledCustomizedWrapper1>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: { xs: 400, sm: 500, md: 600 },
                    overflow: 'hidden',
                  }}
                >
                  <canvas ref={chartRef} id="myChart" style={{ width: '100%', height: '100%' }} />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </StyledCustomizedWrapper1>
    </>
  );
};

export default Growth;

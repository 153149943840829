import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedStatusState {
  selectedStatus: any[];
  hasArtifact:boolean;
  tabValue:String;
}

const initialState: SelectedStatusState = {
  selectedStatus: [],
  hasArtifact: false,
  tabValue: 'All',
};

const selectedStatusSlice = createSlice({
  name: 'selectedStatus',
  initialState,
  reducers: {
    setSelectedStatus: (state, action: PayloadAction<any>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedStatus = action.payload;
    },
    setHasArtifact: (state, action: PayloadAction<any>) => {
      // eslint-disable-next-line no-param-reassign
      state.hasArtifact = action.payload;
    },
    setTabValue: (state, action: PayloadAction<any>) => {
      if (state) {
        // eslint-disable-next-line no-param-reassign
        state.tabValue = action?.payload;
      }
    },
  },
});

export const { setSelectedStatus, setHasArtifact, setTabValue } = selectedStatusSlice.actions;
export default selectedStatusSlice.reducer;
